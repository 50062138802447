import React from 'react';
import { graphql } from 'gatsby';
import withPreview from '../utils/with-preview';
import PageGenericTemplate from './pageGenericTemplate';

const PageTemplate = ({
  data: { liferayPageGeneric, allLiferayModuleHighlightTopPage },
  location,
}) => {
  const bannerTop = liferayPageGeneric?.bannerTop
    ? liferayPageGeneric?.bannerTop?.value
    : allLiferayModuleHighlightTopPage?.nodes?.find(
        (highlight) => highlight?.showOnAllPagesBannerTop?.value === true
      )
    ? allLiferayModuleHighlightTopPage?.nodes?.find(
        (highlight) => highlight?.showOnAllPagesBannerTop?.value === true
      )
    : null;
  return (
    <PageGenericTemplate
      liferayPageGeneric={liferayPageGeneric}
      location={location}
      isEmbedded={false}
      bannerTop={bannerTop}
    />
  );
};

export const query = graphql`
  query ($id: String) {
    liferayPageGeneric(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
        langCode
        taxonomyCategoryNames
      }
      pageBody {
        content {
          anchorBefore {
            value
          }
          anchorTopPageText {
            value
          }
          modules {
            value {
              __typename
              ...QueryFastQuote
              ...QueryFasciaIcone
              ...QueryFasciaIconeTesto
              ...QueryLateralSticker
              ...QueryFasciaInformativaECTA
              ...QueryFasciaProdotto
              ...QueryFasciaCardImg
              ...QueryAgencyLocator
              ...QueryBanner
              ...QueryVideo
              ...QueryHero
              ...QueryHeroLanding
              ...QueryShortlink
              ...QueryFasciaCardEtichettaCircolare
              ...QueryPhotogallery
              ...QueryCardsIconeTestoFooter
              ...QueryVideoGallery
              ...QueryVisoreSliderImmagini
              ...QueryTestoIntroduttivo
              ...QueryBannerImmagineCircolare
              ...QueryAccordion
              ...QueryScaricaApp
              ...QueryTimeline
              ...QueryLinkDocumentList
              ...QueryFasciaTab
              ...QueryFolderTree
              ...QueryFolderTreeAlphabetical
              ...QueryContenutoHtml
              ...QueryFasciaBlog
              ...QueryPhoneBoxes
              ...QueryLandingForm
              ...QueryAssetList
            }
          }
          anchoredModules {
            value {
              __typename
              ...QueryAccordion
              ...QueryAgencyLocator
              ...QueryBanner
              ...QueryBannerImmagineCircolare
              ...QueryCardsIconeTestoFooter
              ...QueryFasciaCardEtichettaCircolare
              ...QueryFasciaCardImg
              ...QueryFasciaIcone
              ...QueryFasciaIconeTesto
              ...QueryFasciaInformativaECTA
              ...QueryFasciaProdotto
              ...QueryFastQuote
              ...QueryHero
              ...QueryLateralSticker
              ...QueryPhotogallery
              ...QueryScaricaApp
              ...QueryShortlink
              ...QueryTestoIntroduttivo
              ...QueryVideo
              ...QueryVideoGallery
              ...QueryVisoreSliderImmagini
              ...QueryTimeline
              ...QueryLinkDocumentList
              ...QueryFasciaTab
              ...QueryFolderTree
              ...QueryFolderTreeAlphabetical
              ...QueryContenutoHtml
              ...QueryFasciaBlog
              ...QueryPhoneBoxes
              ...QueryLandingForm
              ...QueryAssetList
            }
          }
        }
      }
      pageTitle {
        value
      }
      breadcrumbs {
        ... on LiferayPageGeneric {
          pageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailProduct {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailBlog {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailIniziative {
          detailPageTitle {
            value
          }
          link
        }
      }
      customField {
        content {
          disableLinkLogo {
            value
          }
          pageType {
            value
          }
          showChangeLanguageButton {
            value
          }
          hideSocialButton {
            value
          }
          showBreadcrumb {
            value
            content {
              showHomePage {
                value
              }
            }
          }
          showNavigationMenu {
            value
            content {
              ctaLabel {
                value
              }
              ctaLink {
                value
              }
              showBackButton {
                value
                content {
                  labelBackButton {
                    value
                  }
                }
              }
            }
          }
          scripts {
            value
          }
        }
      }
      seo {
        value {
          ... on LiferaySubmoduleSeo {
            ...QuerySeo
          }
        }
      }
      bannerTop {
        value {
          ... on LiferayModuleHighlightTopPage {
            ...QueryHighlightTopPage
          }
        }
      }
      link
      linkLangMap
      lateralStickerAnchor
    }
    allLiferayModuleHighlightTopPage {
      nodes {
        bannerTop {
          content {
            bannerTopImage {
              node {
                gatsbyImageData(width: 100)
              }
            }
            colorBannerTop {
              value
            }
            ctaLabelBannerTop {
              value
              content {
                ctaColorBannerTop {
                  value
                }
                ctaLinkBannerTop {
                  value
                }
                ctaTypeBannerTop {
                  value
                }
                iconSvgIdCtaBannerTop {
                  value
                  content {
                    iconThicknessCtaBannerTop {
                      value
                    }
                  }
                }
              }
            }
            iconSvgIdBannerTop {
              value
              content {
                iconThicknessBannerTop {
                  value
                }
              }
            }
            tagTitleBannerTop {
              value
            }
            textBannerTop {
              value
            }
            titleBannerTop {
              value
            }
          }
        }
        showOnAllPagesBannerTop {
          value
        }
        htmlTagWrapper {
          value
        }
        liferayFields {
          articleId
          siteId
        }
      }
    }
  }
`;

export default withPreview(PageTemplate, {
  subField: 'liferayPageGeneric',
  fixed: true,
});
